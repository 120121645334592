
import Vue from 'vue';
import { Component, Prop } from 'vue-property-decorator';
import ContainerEvent from "@/models/ContainerEvent.model";
import {TABLE_FOOTER_OPTIONS} from "@/misc/TableFooterOptions";
import {EventStatus} from "@/enum/EventStatus.enum";

@Component
export default class HistoryViewComponent extends Vue {
  @Prop({ required: true })
  public history!: ContainerEvent[];

  @Prop({ default: false })
  private isLoading!: boolean;

  @Prop({ default: '' })
  private search!: string;

  /**
   * headers of the table
   */
  private headers = [
    { text: this.$t('HISTORY.CONTAINER'), align: 'start', sortable: true, value: 'container.type.name' },
    { text: this.$t('HISTORY.EVENT_TIME'), align: 'start', sortable: true, value: 'eventTime', formatter: this.translateDate },
    { text: this.$t('HISTORY.EVENT_STATUS'), align: 'start', sortable: true, value: 'eventStatus', statusFormatter: this.translateStatus },
    { text: this.$t('HISTORY.DEADLINE'), align: 'start', sortable: true, value: 'transaction.deadline', formatter: this.translateDate },
    { text: this.$t('HISTORY.RETURNED_ON'), align: 'start', sortable: true, value: 'transaction.returnedOn', formatter: this.translateDate }
  ];

  /**
   * options for the footer
   * @private
   */
  private footerOptions = TABLE_FOOTER_OPTIONS;

  /**
   * translates the date with the custom translater plugin, checks if the date is set to avoid 'invalid dateTime's in
   * the table
   * @param date
   * @private
   */
  private translateDate(date?: string): string {
    // if date doesn't exist, just return placeholder
    if(!date) return '-';

    // return the translated date
    return this.$formatDateFromString(date);
  }

  /**
   * translates the status based on the passed status
   * @param status
   * @private
   */
  private translateStatus(status?: EventStatus) {
    // if status doesn't exist, just return placeholder
    if(!status) return '-';

    // returns translated status
    return this.$t(`EVENT_STATUS.${status}`);
  }
}
